import React, { useState } from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "../components/Card";
import { map } from "lodash";
import { Helmet } from "react-helmet";
import Seo from "../components/Seo";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Blog({
  data: {
    allContentfulBlog: { nodes: blogs },
    allContentfulCategory: { nodes: cats },
  },
}) {
  const [state, setState] = useState("TOUT");

  function handleClick(e) {
    e.preventDefault();

    setState(e.target.innerText.toUpperCase());
  }

  return (
    <>
      <Seo title="Blog" />
      <Layout>
        {/* <Helmet>
        <title>Scalable Blog</title>
        <meta
          name="description"
          content="Get stats about every music from every movie"
        />
        <meta name="keywords" content="Music, Audio, Lyrics" />
      </Helmet> */}

        <section className="blogs-page">
          <section className="section">
            <Typography component="h1" variant="h2" align="center">
              Blog
            </Typography>
            <div
              style={{
                margin: "20px auto",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Button
                onClick={handleClick}
                color="primary"
                size="large"
                variant="contained"
              >
                TOUT
              </Button>
              {cats.map((cat, index) => {
                if (cat.blog !== null) {
                  return (
                    <Button
                      key={index}
                      style={{ marginLeft: "10px" }}
                      onClick={handleClick}
                      color="secondary"
                      size="large"
                      variant="outlined"
                      value={cat.name}
                    >
                      {cat.name}
                    </Button>
                  );
                }
              })}
            </div>
            <div className="section-center services-center">
              {blogs.map((blog) => {
                let image;
                if (blog.thumbnail) {
                  image = blog.thumbnail.fixed;
                } else {
                  image = undefined;
                }
                if (state === blog.category.name.toUpperCase()) {
                  return (
                    <Card
                      slug={"blog/" + blog.slug}
                      key={blog.id}
                      title={capitalizeFirstLetter(blog.title)}
                      image={image}
                      cta="En savoir plus"
                    />
                  );
                } else if (state === "TOUT") {
                  return (
                    <Card
                      slug={"blog/" + blog.slug}
                      key={blog.id}
                      title={capitalizeFirstLetter(blog.title)}
                      image={image}
                      cta="En savoir plus"
                    />
                  );
                }
              })}
            </div>
          </section>
        </section>
      </Layout>
    </>
  );
}

export const query = graphql`
  query Articles {
    allContentfulBlog {
      nodes {
        title
        slug
        id
        category {
          name
        }
        thumbnail {
          fixed {
            ...GatsbyContentfulFixed
          }
        }
      }
    }

    allContentfulCategory {
      nodes {
        name
        blog {
          id
        }
      }
    }
  }
`;

export default Blog;
